<footer class="footer">
  <div class="columns footer-top">
    <div class="container container-footer">
      <div class="column">
        <h5 class="title-text-footer">Контакты</h5>
        <ul class="contact-footer">
          <li><a href="tel:0700 220044"> 0700 220044 </a></li>
          <li><a href="tel:0770 220044"> 0770 220044 </a></li>
          <li><a href="tel:0550 220044"> 0550 220044 </a></li>
        </ul>
      </div>
      <div class="column rounded-social-buttons">
        <h5 class="title-text-footer"></h5>
        <a class="social-button facebook" href="https://www.facebook.com/ijaraber.kg/" target="_blank"><i
          class="fab fa-facebook-f"></i></a>
        <a class="social-button instagram" href="https://instagram.com/ijaraber?igshid=fpl6uwz45teq" target="_blank"><i
          class="fab fa-instagram"></i></a>
        <!--<a class="social-button twitter" href="https://www.twitter.com/" target="_blank"><i
                class="fab fa-twitter"></i></a>
        <a class="social-button linkedin" href="https://www.linkedin.com/" target="_blank"><i
                class="fab fa-linkedin"></i></a>
        <a class="social-button youtube" href="https://www.youtube.com/" target="_blank"><i
                class="fab fa-youtube"></i></a>-->
      </div>
    </div>
  </div>
  <div class="content has-text-centered footer-bottom">
    <p>Copyright © 2020 All Rights Reserved | Created by <a href="http://aikosoft.io/" target="_blank" class="footer-btn">AikoSoft</a>.</p>
  </div>
</footer>
