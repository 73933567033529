import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isModalActive = false;
  public phone = '';

  constructor(private authService: AuthService,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  login() {
    console.log(this.phone);
    this.authService.login(this.phone)
      .subscribe((result) => {
        console.log(result);
        if (result) {
          this.router.navigate(['/exam-result-list']);
        } else {
          console.log('We could not find you!');
        }
      });
  }

  showModal() {
    this.isModalActive = true;
  }

  closeModal() {
    this.isModalActive = false;
  }
}
