import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isActive = false;

  constructor(private authService: AuthService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.router.navigate(['/exam-result-list']);
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  displayMenu() {
    this.isActive = !this.isActive;
  }
}
