<nav aria-label="main navigation" class="navbar" role="navigation">
  <div class="navbar-brand">
    <a class="navbar-item" routerLink="exam-result-list">
      <img height="28" src="./assets/kreativ-taalim.png" width="112">
    </a>

    <a (click)="displayMenu()" aria-expanded="false" aria-label="menu" class="navbar-burger burger"
       data-target="navbarBasicExample"
       role="button">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div [ngClass]="{'is-active': isActive}" class="navbar-menu" id="navbarBasicExample">
    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <button (click)="logout()" class="button is-info">
            Выйти
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>
