import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import * as html2pdf from 'html2pdf.js';
import {StudentResultsService} from '../services/student-results.service';

@Component({
  selector: 'app-exam-result',
  templateUrl: './exam-result.component.html',
  styleUrls: ['./exam-result.component.scss']
})
export class ExamResultComponent implements OnInit {
  selectedId: string;
  examResult: any;
  totalCorrect: number;
  objectKeys = Object.keys;
  predmetter = ['math', 'analogiya', 'okuuTushunuu', 'grammatika'];
  predmetterName = ['МАТЕМАТИКА', 'АНАЛОГИЯЛАР ЖАНА СҮЙЛӨМДӨРДҮ ТОЛУКТОО', 'ЭНЕ ТИЛИНДЕ ОКУУ ЖАНА ТҮШҮНҮҮ', 'ЭНЕ ТИЛИНИН ПРАКТИКАЛЫК ГРАММАТИКАСЫ'];
  predmetterNameRu = ['Математика', 'АНАЛОГИИ И ДОПОЛНЕНИЕ ПРЕДЛОЖЕНИЙ', 'ЧТЕНИЕ И ПОНИМАНИЕ', 'ПРАКТИЧЕСКАЯ ГРАММАТИКА РУССКОГО ЯЗЫКА'];
  mapPoints = ['mathsTotalPoints', 'analogiyaTotalPoints', 'okuuTotalPoints', 'grammatikaTotalPoints'];
  mapNumberOfQuestions = [60, 30, 30, 30];
  mapPercentage: any = {};

  constructor(private authService: AuthService,
              private route: ActivatedRoute,
              private studentResultsService: StudentResultsService,
              private router: Router) {
  }

  ngOnInit(): void {
    const phoneNumber = this.authService.getPhoneNumber();

    if (!phoneNumber) {
      this.router.navigate(['/login']);
    }

    this.route.paramMap.subscribe(params => {
      this.selectedId = params.get('id');

      this.studentResultsService.query({phone: phoneNumber, examId: this.selectedId}).subscribe((res) => {
        console.log(res);
        this.examResult = res[0];
        if (this.examResult.group === 'R') {
          this.predmetterName = this.predmetterNameRu;
        }
        const points = this.examResult.points;
        this.totalCorrect = points.mathTotal + points.analogiyaTotal + points.okuuTushunuuTotal + points.grammatikaTotal;
        this.mapPercentage.math = this.calculatePercentage(60, points.mathTotal);
        this.mapPercentage.analogiya = this.calculatePercentage(30, points.analogiyaTotal);
        this.mapPercentage.okuuTushunuu = this.calculatePercentage(30, points.okuuTushunuuTotal);
        this.mapPercentage.grammatika = this.calculatePercentage(30, points.grammatikaTotal);

        this.predmetter.forEach((boluk) => {
          Object.keys(this.examResult.categoryRes[boluk]).forEach((predmet) => {
            let total = 0;
            let correct = 0;
            this.examResult.categoryRes[boluk][predmet].subCategories.forEach((cat, catInd) => {
              total += cat.total;
              correct += cat.correct;
            });
            this.examResult.categoryRes[boluk][predmet].total = total;
            this.examResult.categoryRes[boluk][predmet].correct = correct;
            this.examResult.categoryRes[boluk][predmet].percentage = this.calculatePercentage(total, correct);
          });
        });
        console.log(this.examResult);
      });
    });
  }

  calculatePercentage(total: number, value: number) {
    if (value > 0) {
      return (100 * value / total).toPrecision(3);
    }
    return 0;
  }

  generatePdf() {
    const element = document.getElementById('exam-result');
    const downloadDiv = document.getElementById('download-div');
    const pdfOptions = {
      html2canvas: {scale: 4},
      filename: 'орт' + this.examResult.testNumber + '.pdf'
    };

    downloadDiv.style.display = 'none';

    html2pdf()
      .set(pdfOptions)
      .from(element)
      .save();

    setTimeout(() => {
      downloadDiv.style.display = 'block';
    }, 3000);
  }

}
