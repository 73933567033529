import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './home/home.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthService} from './services/auth.service';
import {NotificationService} from './services/notification.service';
import {ExamResultListComponent} from './exam-result-list/exam-result-list.component';
import {ExamResultComponent} from './exam-result/exam-result.component';
import {NotificationComponent} from './notification/notification.component';
import {FooterComponent} from './footer/footer.component';
import {HttpClientModule} from '@angular/common/http';
import {StudentResultsService} from './services/student-results.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NotFoundComponent,
    ExamResultListComponent,
    ExamResultComponent,
    NotFoundComponent,
    NotificationComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [
    AuthService,
    NotificationService,
    StudentResultsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
