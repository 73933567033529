<div class="column">
  <h2 class="has-text-centered subtitle">Жалпы ЖРТ жыйынтыктары</h2>
  <table class="table is-bordered is-striped" *ngIf="examResultList">
    <thead>
    <tr>
      <th>#</th>
      <th>Аталышы</th>
      <th>Дата</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let exam of examResultList; let i=index">
      <th>{{i + 1}}</th>
      <td><a [routerLink]="['/exam-result', exam._id]"> {{exam.displayName}} </a></td>
      <td> {{exam.createdAt | date: "dd/MM/yyyy"}}</td>
    </tr>
    </tbody>
  </table>

</div>
