import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {NotificationService} from './notification.service';
import {StudentResultsService} from './student-results.service';

const PHONE_NUMBER = 'phone';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private notificationService: NotificationService,
              private studentResultsService: StudentResultsService) {
  }

  getPhoneNumber(): string {
    return localStorage.getItem(PHONE_NUMBER) || '';
  }

  login(phone: string) {
    return this.studentResultsService.query({phone}).pipe(
      map((data) => {
        if (!data || data.length === 0) {
          this.notificationService.update('Мы не смогли найти ваш аккаунт', 'error');
          return false;
        } else {
          console.log(data);
          const student = data[0];
          console.log(student);
          localStorage.setItem(PHONE_NUMBER, phone);
          // localStorage.setItem(STUDENT_INFO, JSON.stringify(student));
          this.notificationService.update('Добро пожаловать', 'success');
          return true;
        }
      })
    );
  }

  logout() {
    this.notificationService.update('Пока :(', 'info');
    localStorage.clear();
  }

  isLoggedIn(): boolean {
    const phoneNumber = localStorage.getItem(PHONE_NUMBER);
    return (phoneNumber && phoneNumber.length > 0);
  }
}
