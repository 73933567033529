<section class="hero is-fullheight">
  <div class="hero-body">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-5-tablet is-4-desktop is-3-widescreen">
          <form class="box">
            <div class="field">
              <figure class="image is-128x128 logo">
                <img src="assets/kreativ-taalim.png">
              </figure>
              <h3>Здесь вы можете узнать результаты пробного тестирования ОРТ</h3>
              <br>
              <p style="color: red;">Внимание!!! Номер телефона должно быть в формате XXX XXXXXX. Пример: 0595 123456</p>
            </div>
            <div class="field">
              <div class="control has-icons-left">
                <label>
                  <input type="phone" name="phone_number" placeholder="0XXX123456" class="input" [(ngModel)]="phone">
                </label>
                <span class="icon is-small is-left">
                  <i class="fa fa-phone"></i>
                </span>
              </div>
            </div>
            <div class="field is-grouped is-grouped-centered">
              <button class="button is-success" (click)="login()" [disabled]="phone.length==0">
                Найти
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
