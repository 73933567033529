import {Component, OnInit} from '@angular/core';
import {ExamResultsService} from '../services/exam-result.service';
import {AuthService} from '../services/auth.service';
import {Router} from '@angular/router';
import {StudentResultsService} from '../services/student-results.service';

@Component({
  selector: 'app-exam-result-list',
  templateUrl: './exam-result-list.component.html',
  styleUrls: ['./exam-result-list.component.scss']
})
export class ExamResultListComponent implements OnInit {
  examResultList: any[];

  constructor(private examResultService: ExamResultsService,
              private authService: AuthService,
              private studentResultsService: StudentResultsService,
              private router: Router) {
  }

  ngOnInit(): void {
    const phoneNumber = this.authService.getPhoneNumber();
    console.log(phoneNumber);
    if (!phoneNumber) {
      this.router.navigate(['/login']);
    }
    this.studentResultsService.query({phone: phoneNumber}).subscribe((studentResults) => {
      console.log(studentResults);
      const examIds = [];
      studentResults.forEach((res: any) => {
        examIds.push(res.examId);
      });

      const uniqueExamIds = examIds.filter((v, i, a) => a.indexOf(v) === i);
      console.log(examIds);
      console.log(uniqueExamIds);
      this.examResultService.query({examIds: uniqueExamIds}).subscribe((data) => {
        console.log(data);
        this.examResultList = data;
      });
    });
  }

}
