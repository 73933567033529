<div *ngIf="examResult" class="" id="exam-result">
  <div class="empty-divider"></div>
  <div class="container">
    <h2 class="subtitle has-text-centered">Результаты пробного тестирования<br>Детальный анализ результаты тестов</h2>
    <div class="empty-divider"></div>
  </div>
  <div class="empty-divider"></div>
  <div class="empty-divider"></div>
  <div class="empty-divider"></div>

  <div class="container">
    <div class="empty-divider"></div>
    <div class="author-container">
      <h1 class="">
        ФИО: {{examResult.surname}} {{examResult.name}} <br>
        Дата: {{examResult.createdAt | date: 'dd/MM/yyyy, h:mm a'}} <br>
        Тест номер: {{examResult.testNumber}}
      </h1>

      <div class="download-div" id="download-div">
        <span class="icon">
          <img alt="" src="./assets/pdf.svg">
        </span>
        <a (click)="generatePdf()"> Скачать </a>
      </div>
    </div>

    <table class="table is-bordered  is-striped">
      <thead>
      <tr>
        <th>Предмет</th>
        <th>КПО/ТЖС</th>
        <th>Балл</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Математика</td>
        <td class="has-text-centered">{{examResult.points.mathTotal}}</td>
        <td class="has-text-centered">{{examResult.mathsTotalPoints}}</td>
      </tr>
      <tr>
        <td>Аналогия/Окшоштуктар</td>
        <td class="has-text-centered">{{examResult.points.analogiyaTotal}}</td>
        <td class="has-text-centered">{{examResult.analogiyaTotalPoints}}</td>
      </tr>
      <tr>
        <td>Чтение и пон/Окуу жана туш.</td>
        <td class="has-text-centered">{{examResult.points.okuuTushunuuTotal}}</td>
        <td class="has-text-centered">{{examResult.okuuTotalPoints}}</td>
      </tr>
      <tr>
        <td>Грамматика</td>
        <td class="has-text-centered">{{examResult.points.grammatikaTotal}}</td>
        <td class="has-text-centered">{{examResult.grammatikaTotalPoints}}</td>
      </tr>
      <tr>
        <th>Общий балл/Жалпы балл</th>
        <td class="has-text-centered">{{totalCorrect}}</td>
        <td class="has-text-centered">{{examResult.totalPoints}}</td>
      </tr>
      </tbody>
    </table>
    <div class="empty-divider"></div>
  </div>

  <div class="orange-divider"></div>

  <div class="table-container">
    <table class="table is-narrow">
      <tr class="header-name">
        <th></th>
        <th class="has-text-centered">Суроо саны <br> Кол. вопросов</th>
        <th class="has-text-centered">Туура ж. саны <br> Кол. прав. отв.</th>
        <th class="has-text-centered">% <br> %</th>
        <th class="has-text-centered">Балл <br> Балл</th>
      </tr>
      <ng-container *ngFor="let predmet of predmetter; let aty=index;">
        <tr class="category-name">
          <th>{{predmetterName[aty]}}</th>
          <th class="has-text-centered">{{mapNumberOfQuestions[aty]}}</th>
          <th class="has-text-centered">{{examResult.points[predmet + 'Total']}}</th>
          <th class="has-text-centered">{{mapPercentage[predmet]}}%</th>
          <th>{{examResult[mapPoints[aty]]}}</th>
        </tr>
        <ng-container *ngFor="let keys of objectKeys(examResult.categoryRes[predmet])" class="category">
          <tr *ngIf="predmetterName[aty]!==predmetterName[2]">
            <th class="subcategory-name">{{examResult.categoryRes[predmet][keys].name}}</th>
            <th class="has-text-centered">{{examResult.categoryRes[predmet][keys].total}}</th>
            <th class="has-text-centered">{{examResult.categoryRes[predmet][keys].correct}}</th>
            <th class="has-text-centered">{{examResult.categoryRes[predmet][keys].percentage}}%</th>
          </tr>

          <tr *ngFor="let subcategory of examResult.categoryRes[predmet][keys].subCategories; let jj=index">
            <td class="topic-name">{{subcategory.name}}</td>
            <td class="has-text-centered">{{subcategory.total}}</td>
            <td class="has-text-centered">{{subcategory.correct}}</td>
          </tr>
        </ng-container>

      </ng-container>
    </table>
  </div>

</div>
<div class="empty-divider"></div>
